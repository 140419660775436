import {
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import React from "react";
import { BsTools, FaDownload } from "react-icons/all";
import Projects from "../components/Projects";

const About = () => {
  return (
    <>
      <List>
        <ListItem>
          <Card style={{ textAlign: "center" }}>
            <CardContent>
              <img
                height={200}
                style={{
                  borderRadius: 100,
                  margin: 10,
                }}
                alt={"William Spiegel"}
                src={
                  "https://i.imgur.com/apDYtEI.jpg"
                }
              />
              <Typography variant={"h3"}>William Spiegel</Typography>
              <Typography style={{ paddingBottom: 15 }} variant={"h5"}>
                Mobile Developer
              </Typography>
              <Divider />
              <Typography
                align={"left"}
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ padding: 15 }}
              >
                Hey there! I enjoy building user interfaces and getting creative
                with utilizing various APIs in order to deliver the best
                possible user experience with React Native, React, and Flutter.
                Check out my GitHub as I open source and polish some of my old
                projects and build some new projects from time to time. Feel
                free to get in touch with me on other platforms as well.
              </Typography>
              <Divider />
              <div
                style={{
                  flexDirection: "row",
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: 15 }}>
                  <Typography>Download Resume</Typography>

                  <IconButton
                    href={
                      "https://www.dropbox.com/s/jpwg76f7znl88th/William_Spiegel_Resume.pdf?dl=1"
                    }
                  >
                    <FaDownload size={50} />
                  </IconButton>
                </div>

              
              </div>
            </CardContent>
          </Card>
        </ListItem>
        <Divider style={{ margin: 15 }} />
        <Typography variant={"h3"} style={{ textAlign: "center" }}>
          <BsTools /> Projects <BsTools />
        </Typography>
        <Divider style={{ margin: 15 }} />

        <Projects />
      </List>
    </>
  );
};
export default About;
