import { Button, Container, Snackbar, Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  Close,
  Email,
  GitHub,
  HelpOutlineRounded,
  LinkedIn, SportsEsports,
  Twitter
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { BiMoon, BiSun, FaDownload } from "react-icons/all";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import PhoneWarningDialog from "./components/PhoneWarningDialog";
import About from "./Views/About";
import Blog from "./Views/Blog";
import FavoriteGames from "./Views/FavoriteGames";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  text: { color: theme.palette.text },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 25,
    flex: 1,
    color: theme.palette.text.primary,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Routes({ window, toggleTheme, darkMode }) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isPhoneDialogOpen, setIsPhoneDialogOpen] = useState(false);
  const theme = useTheme();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerOff = () => {
    setMobileOpen(false);
  };
  const openSnackbar = (phoneWarning) => {
    if (!snackbarOpen) {
      setSnackbarOpen(true);
    }
    if (phoneWarning) {
      setIsPhoneDialogOpen(true);
    }
  };
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItem component={Link} to={"/"} onClick={drawerOff} button>
          <ListItemIcon>
            <HelpOutlineRounded />
          </ListItemIcon>
          <ListItemText>About Me</ListItemText>
        </ListItem>
        <Divider />
        <ListItem component={Link} to={"/blog"} onClick={drawerOff} button>
          <ListItemIcon>
            <Twitter />
          </ListItemIcon>
          <ListItemText>Twitter Blog</ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to={"/favorite-games"}
          onClick={drawerOff}
          button
        >
          <ListItemIcon>
            <SportsEsports />
          </ListItemIcon>
          <ListItemText>Favorite Games</ListItemText>
        </ListItem>
        <Divider />

        <div
          style={{
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          <IconButton href={"https://github.com/williamspiegel"}>
            <GitHub />
          </IconButton>

          <IconButton href={"https://twitter.com/will_spiegel"}>
            <Twitter />
          </IconButton>

          <IconButton href={"https://www.linkedin.com/in/william-spiegel/"}>
            <LinkedIn />
          </IconButton>
        </div>
        <div
          style={{
            paddingTop: 20,
            textAlign: "center",
            // flexDirection: "row",
          }}
        >
        
          <CopyToClipboard
            onCopy={() => openSnackbar(false)}
            text={"willy.spiegel@gmail.com"}
          >
            <Tooltip title={"copy to clipboard"} placement={"right"} arrow>
              <Button style={{ flex: 1, textTransform: "none" }}>
                <Email />
                <Typography style={{ paddingLeft: 10 }}>
                  willy.spiegel@gmail.com
                </Typography>
              </Button>
            </Tooltip>
          </CopyToClipboard>
          <Button
            href={
              "https://www.dropbox.com/s/jpwg76f7znl88th/William_Spiegel_Resume.pdf?dl=1"
            }
            style={{
              textAlign: "center",
              justifyContent: "center",
              textTransform: "none",
            }}
          >
            <FaDownload size={20} />
            <Typography style={{ paddingLeft: 10 }}>Download Resume</Typography>
          </Button>
        
        </div>
      </List>

      <Typography
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 10,
          width: "100%",

          paddingTop: 10,
        }}
      >
        <Divider style={{ marginBottom: 10 }} />
        Made by William Spiegel
      </Typography>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Router>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.headerText} noWrap>
              <Switch>
                <Route path="/projects">Projects</Route>
                <Route path="/blog">Twitter Blog</Route>
                <Route path="/favorite-games">Favorite Games</Route>
                <Route path="/">About Me</Route>
              </Switch>
            </Typography>
            <IconButton onClick={toggleTheme}>
              {darkMode ? <BiSun /> : <BiMoon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container disableGutters maxWidth={"sm"}>
            <Switch>
              <Route path="/blog">
                <Blog darkMode={darkMode} />
              </Route>
              <Route path="/favorite-games">
                <FavoriteGames />
              </Route>
              <Route path="/">
                <About />
              </Route>
            </Switch>
          </Container>
        </main>
      </div>
      <PhoneWarningDialog
        isOpen={isPhoneDialogOpen}
        onClose={() => setIsPhoneDialogOpen(false)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message="Copied to Clipboard!"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <Close fontSize={"small"} />
            </IconButton>
          </>
        }
      />
    </Router>
  );
}
