import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItem,
  Typography,
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/all";
import { GitHub } from "@material-ui/icons";

const cardData = [
  {
    src: [
      "https://i.imgur.com/tp0fSmX.png",
      "https://i.imgur.com/0tS7s9c.png",
      "https://i.imgur.com/sJXMXrv.png",
      "https://i.imgur.com/woJ8c76.png",
      "https://i.imgur.com/eHL0f0R.png",
    ],
    title: "Swipey for Reddit",
    subheader: "Built in Flutter for iOS, Android, MacOS, Windows, and Web",
    description:
      "The idea for Swipey sprung up from my frustrations with the official Reddit app. There are many long-term bugs with the official app such as collapsing comments spontaneously not working in the middle of browsing content and tons of design elements not fit for a mobile context. I am building Swipey around the way I normally browse Reddit — in a carousel orientation. The app always stays in a carousel screen, with navigation between subreddits being served in a bottom sheet for better thumb reachability.",
  },
  {
    title: "MyVax and MIMI-Rx",
    subheader: "Built in React Native for iOS and Android",
    description:
      "MyVax and MIMI-Rx are apps owned by EagleForce targeted towards the healthcare space for patients and providers to have their medications, immunizations, vitals, and healthcare documents all in one digital place. They are essentially the same app but with different branding. MyVax just has some features in the sidebar taken away to cater to everyday users more, and it is also more heavily marketed because of this.",
    src: [
      "https://play-lh.googleusercontent.com/EHvMaO4uKrd2YN7liIPE9vOn0sIzHXQ-KNJSKkqlYgbrikbjmpVqxK1rt_Kb20JHEGE=w2150-h1230-rw",
      "https://play-lh.googleusercontent.com/KRdTfi-noYepb1fWbw78SC_vPvNOniAXkxKsN5TgcwEPhyDX3hw-xVLhZ0MlCQb2uqYv=w2150-h1230-rw",
      "https://play-lh.googleusercontent.com/bjCu7772OUzy69Vexl0KUxUqFyEtPYxC2F3X8n8KijaLShJ65E48s794O9iiXRS0Tg=w2150-h1230-rw",
      "https://play-lh.googleusercontent.com/O5F9OklS90s_6APYg7tC8XK6dxaR8nDIvoMnBFzTqTUnifDvVe0NZvkvl9Sej_WA_ZM=w2150-h1230-rw",
      "https://play-lh.googleusercontent.com/uaMDHIQWZAEL7x5IghDEPo-XGaX4d0mDZ82MNo-r6FzQwPJe5qOxBUbmk8uutYfTCUmW=w2150-h1230-rw",
    ],
    playStore:
      "https://play.google.com/store/apps/details?id=com.eagleforce.myVax&hl=en_US&gl=US",
    appStore: "https://apps.apple.com/us/app/myvax/id1529542003",
  },
  {
    src: ["https://i.imgur.com/CQz8LxI.png", "https://i.imgur.com/DegJA3n.png"],
    title: "Swipey Classic for Reddit ",
    subheader: "Built in React Native for iOS and Android",
    description: `This was my first implementation of Swipey. One of the major pitfalls of trying to make complicated and uncommon UI features such as deeply nested collapsible comments is the lack of first party support. Using a cross platform framework powered by JavaScript runs into many issues such as third party packages abusing JavaScript's single threaded limitation for animations instead of offloading these animations to native threads with packages like React Native Reanimated. Issues like this lead to React Native state updates to be blocked longer than expected, resulting in slower response time to user input. This issue is magnified on Android devices since their CPUs handle single threaded operations much worse compared to iPhones. 
    React Native is very useful for general business use where there isn't so many things going on at once in a UI, and it excels at rapid prototyping (even more than Flutter in my opinion). Unfortunately, it does not meet my performance standards specifically with this project due to the complex business logic that I want to achieve, so I am uncomfortable listing this implementation on the Play Store and App Store. I have open sourced and cleaned up this project for evaluation purposes and am open to Pull Requests.`,
    github: "https://github.com/williamspiegel/Swipey",
  },
];

const _renderCarouselContent = (src) => (
  <div>
    <img style={{ maxWidth: 250 }} src={src} alt={""} />
  </div>
);

const _renderCard = ({
  src,
  title,
  subheader,
  description,
  playStore,
  appStore,
  github,
}) => (
  <ListItem>
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <Divider />
        <Typography
          style={{ paddingTop: 10, paddingBottom: 10 }}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {description}
        </Typography>
        <Divider style={{ marginBottom: 40 }} />
        <Carousel showThumbs={false}>
          {src.map(_renderCarouselContent)}
        </Carousel>
      </CardContent>
      <CardActions>
        {playStore && (
          <IconButton href={playStore}>
            <IoLogoGooglePlaystore />
          </IconButton>
        )}
        {appStore && (
          <IconButton href={appStore}>
            <IoLogoAppleAppstore />
          </IconButton>
        )}
        {github && (
          <IconButton href={github}>
            <GitHub />
          </IconButton>
        )}
      </CardActions>
    </Card>
  </ListItem>
);

export default function Projects() {
  return <>{cardData.map(_renderCard)}</>;
}
