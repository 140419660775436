import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { IconButton, List, ListItem } from "@material-ui/core";
import { Reddit, Web, YouTube } from "@material-ui/icons";
import { FaSteam } from "react-icons/all";

const useStyles = makeStyles({
  root: {
    maxWidth: 900,
  },
  media: {
    padding: 10,
  },
});
const gameList = [
  {
    title: "Path of Exile",
    image: "https://i.imgur.com/IINVt8L.png",
    site: "https://www.pathofexile.com/",
    reddit: "https://www.reddit.com/r/pathofexile/",
    steam: "https://store.steampowered.com/app/238960/Path_of_Exile/",
    youtube: "https://www.youtube.com/user/grindinggear",
    description: `Path of Exile is hands down my favorite game of all time. Although I don't play games as much as I used to, I always set aside a weekend for every new expansion that comes out every three or so months. It is a true spiritual successor to Diablo 2 with its gothic colors and atmosphere in addition to its extreme difficulty and character diversity. The developers are very responsive to player feedback on their forums and subreddit, but they selectively ignore feedback that violate their core philosophies of a fun, authentic ARPG experience.`,
  },
  {
    title: "Old School RuneScape",
    image: "https://i.imgur.com/MIUmFXU.png",
    site: "https://oldschool.runescape.com/",
    reddit: "https://www.reddit.com/r/2007scape/",
    youtube: "https://www.youtube.com/user/OldSchoolRSCommunity",
    steam: "https://store.steampowered.com/app/1343370/Old_School_RuneScape/",
    description:
      "I have played RuneScape on and off since 2006. What drove me to the game was the elegance and simplicity of the combat system in addition to the interactivity between the inventory system and the game world. Its quests are truly one of a kind with excellent writing. As the game matured, the combat system was completely overhauled to an ability bar system in order to give more room for new content due to existing power creep. Many players quit until the developers opened Old School RuneScape servers from a snapshot of the game from 2007. The number of active players of Old School RuneScape now surpass those of the mainline game several times over due to excellent content patches and stricter community polls.",
  },
  {
    title: "Risk of Rain 2",
    image: "https://i.imgur.com/Vy4aKLh.png",
    site: "https://www.riskofrain.com/",
    steam: "https://store.steampowered.com/app/632360/Risk_of_Rain_2/",
    reddit: "https://www.reddit.com/r/riskofrain/",
    youtube: "https://www.youtube.com/user/spacegame2012",
    description:
      "Risk of Rain 2 is the best 3D roguelite shooter on the market. One of my favorite things about the gameplay is how difficulty scales with time, so time management is crucial. The procedural generation of levels in the game gives a fresh experience every playthrough and is what makes the genre so exciting",
  },
];
export default function FavoriteGames() {
  const classes = useStyles();
  const _renderCard = (item) => {
    return (
      <ListItem>
        <Card className={classes.root}>
          <CardActionArea href={item.site}>
            <CardMedia
              component={"img"}
              className={classes.media}
              image={item.image}
              title={item.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {item.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <IconButton href={item.site}>
              <Web />
            </IconButton>
            <IconButton href={item.steam}>
              <FaSteam />
            </IconButton>
            <IconButton href={item.reddit}>
              <Reddit />
            </IconButton>
            <IconButton href={item.youtube}>
              <YouTube />
            </IconButton>
          </CardActions>
        </Card>
      </ListItem>
    );
  };

  return <List>{gameList.map(_renderCard)}</List>;
}
