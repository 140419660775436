import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";

export default function App() {
  const [darkMode, setDarkMode] = useState(true);
  const toggleTheme = () => {
    localStorage.setItem("theme", darkMode ? "light" : "dark");
    setDarkMode(!darkMode);
  };
  useEffect(() => {
    const storageTheme = localStorage.getItem("theme");
    setDarkMode(storageTheme !== "light");
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes toggleTheme={toggleTheme} darkMode={darkMode} />
    </ThemeProvider>
  );
}
