import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

export default function Blog({ darkMode }) {
  return (
    <>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="will_spiegel"
        theme={darkMode ? "dark" : "light"}
      />
    </>
  );
}
