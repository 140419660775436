import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
// import { openPopupWidget } from "react-calendly";

export default function PhoneWarningDialog({ isOpen, onClose }) {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make sure you contact me via email or another platform before
            calling me. I do not pick up the phone unless I expect a scheduled
            call. Thanks for understanding!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontWeight: "bold" }}
            onClick={() =>{}
              // openPopupWidget({
              //   url: "https://calendly.com/williamspiegel/30min",
              // })
            }
          >
            Schedule Meeting
          </Button>
          <Button onClick={onClose} style={{ fontWeight: "bold" }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
